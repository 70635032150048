<template>
  <div id="create-activity" :style="{ position: 'relative' }">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5 d-flex justify-space-between">
          <h2 class="d-flex">
            <v-icon @click="back()">arrow_back_ios</v-icon>
            <div class="d-flex align-center">
              {{ templateID ? 'Edit Template' : 'Create Template' }}
            </div>
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primary"
      class="my-10"
    ></v-progress-linear>
    <v-form ref="formReference" v-model="form">
      <v-row no-gutters class="pb-10" v-if="!isLoading">
        <v-col md="12">
          <div class="pa-6 pt-0 pl-10">
            <div class="">
              <div>
                <v-row class="align-center mt-3">
                  <v-col cols="8">
                    <v-text-field
                      v-model="templateTitle"
                      outlined
                      :loading="isLoading"
                      :disabled="isLoading"
                      :rules="[v => !!v || 'This field is required']"
                      label="Title of template *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" class="pt-2 pb-2">
                    <v-autocomplete
                      v-model="templateTags"
                      :items="catListUpdate"
                      item-text="anouncementTagName"
                      item-value="anouncementTagID"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      outlined
                      multiple
                      label="Tags *"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="pt-2 pb-2">
                    <template>
                      <div class="text-center">
                        <div @click="openEditReward" class="d-flex align-center">
                          <img
                            :style="{
                              color: '#246AFF',
                            }"
                            src="../../assets/images/icon-edit-underline-link.svg"
                            alt=""
                          />
                          <h4 class="link ml-5">Edit announcement template tags</h4>
                        </div>
                      </div>
                    </template>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="templateContext"
                      outlined
                      :loading="isLoading"
                      :disabled="isLoading"
                      counter="1000"
                      :rules="[
                        v => !!v || 'This field is required',
                        v => v.length <= 1000 || 'Max 1000 characters',
                      ]"
                      label="Context of template *"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="editor">
                  <span :style="{ color: errorEdittor ? 'red' : '' }">Description *</span>
                  <vue-editor
                    :class="'mt-4'"
                    :style="{ border: errorEdittor ? '2px solid red' : '' }"
                    id="editor"
                    v-model="editor"
                  >
                  </vue-editor>
                  <p :style="{ color: 'red' }" v-if="errorEdittor">This field is require</p>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-footer
      :style="{ position: 'sticky !important' }"
      height="60"
      style="width: 100%"
      color="#fff"
      class="pl-10 pr-10"
      v-if="!isLoading"
    >
      <div class="d-flex justify-end" style="width: 100%">
        <div class="d-flex flex-row-reverse align-center" style="width: 100%">
          <v-btn
            class="mr-3"
            color="primary"
            @click="createNew()"
            :disabled="form && editor ? false : true"
            >Save</v-btn
          >
          <v-btn class="mr-3" @click="back()" :disabled="form && editor ? false : true"
            >Cancel</v-btn
          >
        </div>
      </div>
    </v-footer>
    <v-dialog v-if="dialogH" v-model="dialogH" width="500" persistent>
      <v-card>
        <v-card-title
          :class="'mb-3'"
          :style="{
            width: '100%',
          }"
        >
          <div
            :style="{
              width: '100%',
            }"
          >
            <div class="d-flex justify-space-between mb-2">
              <h3>Announcement template tags</h3>
              <img
                @click="closeDialogCategory()"
                src="../../assets/images/icon-close.svg"
                alt=""
                srcset=""
              />
            </div>
            <v-divider></v-divider>
          </div>
          <v-divider></v-divider>
        </v-card-title>

        <v-card-text>
          <div class="d-flex" :style="{ width: '100%', flexWrap: 'wrap' }">
            <v-form
              class="d-flex"
              :style="{ width: '100%' }"
              ref="formCategory"
              v-model="formCategory"
            >
              <v-text-field
                dense
                v-model="catName"
                label="Tag name"
                outlined
                @input="errCatName = ''"
                @blur="$refs.formCategory.resetValidation()"
                :rules="[validateRequire]"
                :class="'hidden-detail-err '"
              ></v-text-field>
              <v-btn
                :disabled="!formCategory"
                @click="saveCategory()"
                class="ml-2"
                dense
                outlined
                color="primary"
              >
                <img src="../../assets/images/icon-plus-round2.svg" alt="" srcset="" class="mr-2" />
                <p>Add new</p>
              </v-btn>
            </v-form>
          </div>
          <div
            :style="{
              maxHeight: '300px',
              overflowY: 'scroll',
              overflowx: 'hidden',
              paddingTop: '3px',
            }"
          >
            <v-form ref="formTemplateTags" v-model="formTemplateTags">
              <template>
                <div
                  class="d-flex align-center"
                  v-for="(item, index) in catListUpdate"
                  :key="index"
                >
                  <v-col cols="10">
                    <v-text-field
                      :key="index"
                      dense
                      v-model="item.anouncementTagName"
                      outlined
                      label="Name"
                      :rules="[validateRequire]"
                      :class="'hidden-detail-input'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-icon color="red light-2" large @click="removeTag(item.anouncementTagID)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-col>
                </div>
              </template>
            </v-form>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="mr-2">
          <v-spacer></v-spacer>
          <v-btn outlined class="mb-2" @click="closeDialogCategory()"> Cancel </v-btn>
          <v-btn
            :disabled="!formTemplateTags"
            color="primary"
            class="mb-2"
            @click="saveEditCategoryList()"
          >
            Save for edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent width="350">
        <v-card class="mx-auto pa-10">
          <div class="text-center">
            <v-btn
              elevation="0"
              height="36"
              class="pl-4 pr-4 mr-3 mb-5"
              :loading="dialog"
              style="background: white"
            >
            </v-btn>
          </div>
          <div class="text-center">
            <b style="font-size: 1.5rem">Creating!</b><br />
            <span>Please wait...</span>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
//import { center_managerment } from '@/api/center-management.js'
import { announcement } from '@/api/announcement'
// import { referencies } from '@/api/referencies'
import { VueEditor } from 'vue2-editor'
import dayjs from 'dayjs'
// const querystring = require("querystring");
// import { getObjectPermission } from '../../common/index'
export default {
  components: {
    'vue-editor': VueEditor,
  },
  data() {
    return {
      form: false,
      dayjs: dayjs,
      formTemplateTags: false,
      errCatName: '',
      isLoading: false,
      formCategory: false,
      catID: null,
      catName: '',
      catList: [],
      editor: '',
      templateID: this.$route.query.templateID,
      catListUpdate: [],
      dialogH: false,
      dialog: false,
      errorEdittor: false,
      templateTitle: '',
      templateContext: '',
      referenceUrl: '',
      typeOfReference: '',
      authorNames: '',
      categoryIDs: [],
      reference: {},
    }
  },
  created() {
    this.getCategoryList()
    if (this.templateID) this.getTemplateById()
  },
  mounted() {
    // this.$refs.formReference.validate()
  },
  methods: {
    validateRequire(v) {
      return !!v || 'Required'
    },
    async saveCategory() {
      this.$refs.formCategory.validate()
      if (this.formCategory) {
        await announcement
          .editTags([
            {
              tagID: null,
              tagName: this.catName,
            },
          ])
          .then(() => {
            this.getCategoryList()
          })
          .catch(() => {
            this.errCatName = 'This template tag name is exists'
          })
      }
    },
    async saveEditCategoryList() {
      this.$refs.formTemplateTags.validate()
      if (this.$refs.formTemplateTags.validate()) {
        const updateList = this.catListUpdate.map(el => {
          return {
            tagID: el.anouncementTagID,
            tagName: el.anouncementTagName,
          }
        })
        await announcement.editTags(updateList)
        this.getCategoryList()
        this.dialogH = false
      }
    },
    async removeTag(id) {
      await announcement
        .deleteTag(id)
        .then(() => {
          this.getCategoryList()
        })
        .catch(() => {
          this.errCatName = "This template tag ID isn't exists"
        })
    },
    async getCategoryList() {
      await announcement
        .getAllTags()
        .then(res => {
          this.catList = res.sort((a, b) => b.anouncementTagID - a.anouncementTagID)
          this.catListUpdate = res.sort((a, b) => b.anouncementTagID - a.anouncementTagID)
        })
        .catch(() => {
          this.catList = []
        })
    },
    closeDialogCategory() {
      this.dialogH = false
    },
    openEditReward() {
      this.catListUpdate = this.catList
      this.dialogH = true
    },
    async createNew() {
      this.$refs.formReference.validate()
      if (this.form && !this.errorEdittor) {
        if (!this.templateID) {
          const templateData = {
            templateTitle: this.templateTitle,
            templateContext: this.templateContext,
            tagIDs: this.templateTags,
            description: this.editor,
          }
          await announcement.createTemplate(templateData).then(() => {
            this.back()
          })
        } else {
          const templateData = {
            templateID: this.templateID,
            templateTitle: this.templateTitle,
            templateContext: this.templateContext,
            tagIDs: this.templateTags,
            description: this.editor,
          }
          await announcement.editTemplate(templateData).then(() => {
            this.back()
          })
        }
      }
    },
    async getTemplateById() {
      await announcement.getTemplateByID(this.templateID).then(res => {
        this.templateTitle = res.templateTitle
        this.templateContext = res.templateContext
        this.editor = res.description
        this.templateTags = res.tagIDs
      })
    },
    back() {
      window.history.back()
    },
  },
  watch: {
    editor: function () {
      if (!this.editor || this.editor.trim() === '') {
        this.errorEdittor = true
      } else {
        this.errorEdittor = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.message::before {
  content: '';
  position: absolute;
  margin-top: -6px;
  margin-left: -5px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #717ec8;
  transform: rotate(270deg);
  left: -13px;
  top: 45%;
}
.add-img {
  height: 90%;
  img {
    height: 100%;
  }
}

.edit-reference-category {
  width: 200px;
  height: auto;
}
.editor {
  position: relative;
  span {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(10px, -50%);
    background-color: #ffff;
    color: '#5E6368';
  }
}
</style>
