var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ position: 'relative' }),attrs:{"id":"create-activity"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5 d-flex justify-space-between"},[_c('h2',{staticClass:"d-flex"},[_c('v-icon',{on:{"click":function($event){return _vm.back()}}},[_vm._v("arrow_back_ios")]),_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.templateID ? 'Edit Template' : 'Create Template')+" ")])],1)])])],1),(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-form',{ref:"formReference",model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[(!_vm.isLoading)?_c('v-row',{staticClass:"pb-10",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-6 pt-0 pl-10"},[_c('div',{},[_c('div',[_c('v-row',{staticClass:"align-center mt-3"},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"outlined":"","loading":_vm.isLoading,"disabled":_vm.isLoading,"rules":[function (v) { return !!v || 'This field is required'; }],"label":"Title of template *"},model:{value:(_vm.templateTitle),callback:function ($$v) {_vm.templateTitle=$$v},expression:"templateTitle"}})],1),_c('v-col',{staticClass:"pt-2 pb-2",attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"items":_vm.catListUpdate,"item-text":"anouncementTagName","item-value":"anouncementTagID","rules":[function (v) { return v.length > 0 || 'This field is required'; }],"outlined":"","multiple":"","label":"Tags *"},model:{value:(_vm.templateTags),callback:function ($$v) {_vm.templateTags=$$v},expression:"templateTags"}})],1),_c('v-col',{staticClass:"pt-2 pb-2",attrs:{"cols":"6"}},[[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex align-center",on:{"click":_vm.openEditReward}},[_c('img',{style:({
                            color: '#246AFF',
                          }),attrs:{"src":require("../../assets/images/icon-edit-underline-link.svg"),"alt":""}}),_c('h4',{staticClass:"link ml-5"},[_vm._v("Edit announcement template tags")])])])]],2),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","loading":_vm.isLoading,"disabled":_vm.isLoading,"counter":"1000","rules":[
                      function (v) { return !!v || 'This field is required'; },
                      function (v) { return v.length <= 1000 || 'Max 1000 characters'; } ],"label":"Context of template *"},model:{value:(_vm.templateContext),callback:function ($$v) {_vm.templateContext=$$v},expression:"templateContext"}})],1)],1),_c('div',{staticClass:"editor"},[_c('span',{style:({ color: _vm.errorEdittor ? 'red' : '' })},[_vm._v("Description *")]),_c('vue-editor',{class:'mt-4',style:({ border: _vm.errorEdittor ? '2px solid red' : '' }),attrs:{"id":"editor"},model:{value:(_vm.editor),callback:function ($$v) {_vm.editor=$$v},expression:"editor"}}),(_vm.errorEdittor)?_c('p',{style:({ color: 'red' })},[_vm._v("This field is require")]):_vm._e()],1)],1)])])])],1):_vm._e()],1),(!_vm.isLoading)?_c('v-footer',{staticClass:"pl-10 pr-10",staticStyle:{"width":"100%"},style:({ position: 'sticky !important' }),attrs:{"height":"60","color":"#fff"}},[_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row-reverse align-center",staticStyle:{"width":"100%"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","disabled":_vm.form && _vm.editor ? false : true},on:{"click":function($event){return _vm.createNew()}}},[_vm._v("Save")]),_c('v-btn',{staticClass:"mr-3",attrs:{"disabled":_vm.form && _vm.editor ? false : true},on:{"click":function($event){return _vm.back()}}},[_vm._v("Cancel")])],1)])]):_vm._e(),(_vm.dialogH)?_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialogH),callback:function ($$v) {_vm.dialogH=$$v},expression:"dialogH"}},[_c('v-card',[_c('v-card-title',{class:'mb-3',style:({
          width: '100%',
        })},[_c('div',{style:({
            width: '100%',
          })},[_c('div',{staticClass:"d-flex justify-space-between mb-2"},[_c('h3',[_vm._v("Announcement template tags")]),_c('img',{attrs:{"src":require("../../assets/images/icon-close.svg"),"alt":"","srcset":""},on:{"click":function($event){return _vm.closeDialogCategory()}}})]),_c('v-divider')],1),_c('v-divider')],1),_c('v-card-text',[_c('div',{staticClass:"d-flex",style:({ width: '100%', flexWrap: 'wrap' })},[_c('v-form',{ref:"formCategory",staticClass:"d-flex",style:({ width: '100%' }),model:{value:(_vm.formCategory),callback:function ($$v) {_vm.formCategory=$$v},expression:"formCategory"}},[_c('v-text-field',{class:'hidden-detail-err ',attrs:{"dense":"","label":"Tag name","outlined":"","rules":[_vm.validateRequire]},on:{"input":function($event){_vm.errCatName = ''},"blur":function($event){return _vm.$refs.formCategory.resetValidation()}},model:{value:(_vm.catName),callback:function ($$v) {_vm.catName=$$v},expression:"catName"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.formCategory,"dense":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.saveCategory()}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../../assets/images/icon-plus-round2.svg"),"alt":"","srcset":""}}),_c('p',[_vm._v("Add new")])])],1)],1),_c('div',{style:({
            maxHeight: '300px',
            overflowY: 'scroll',
            overflowx: 'hidden',
            paddingTop: '3px',
          })},[_c('v-form',{ref:"formTemplateTags",model:{value:(_vm.formTemplateTags),callback:function ($$v) {_vm.formTemplateTags=$$v},expression:"formTemplateTags"}},[_vm._l((_vm.catListUpdate),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{key:index,class:'hidden-detail-input',attrs:{"dense":"","outlined":"","label":"Name","rules":[_vm.validateRequire]},model:{value:(item.anouncementTagName),callback:function ($$v) {_vm.$set(item, "anouncementTagName", $$v)},expression:"item.anouncementTagName"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"color":"red light-2","large":""},on:{"click":function($event){return _vm.removeTag(item.anouncementTagID)}}},[_vm._v("mdi-trash-can-outline")])],1)],1)})],2)],1)]),_c('v-divider'),_c('v-card-actions',{staticClass:"mr-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.closeDialogCategory()}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":!_vm.formTemplateTags,"color":"primary"},on:{"click":function($event){return _vm.saveEditCategoryList()}}},[_vm._v(" Save for edit ")])],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto pa-10"},[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"pl-4 pr-4 mr-3 mb-5",staticStyle:{"background":"white"},attrs:{"elevation":"0","height":"36","loading":_vm.dialog}})],1),_c('div',{staticClass:"text-center"},[_c('b',{staticStyle:{"font-size":"1.5rem"}},[_vm._v("Creating!")]),_c('br'),_c('span',[_vm._v("Please wait...")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }