import {
    ROOT,
    CREATE_ANNOUN_TEMPLATE,
    CREATE_ANNOUNCEMENT,
    EDIT_ANNOUN_TAGS,
    EDIT_ANNOUN_TEMPLATE,
    EDIT_ANNOUNCEMENT,
    CANCEL_ANNOUNCEMENT,
    DELETE_ANNOUN_TEMPLATE,
    DELETE_ANNOUN_TAG,
    GET_ANNOUN_BY_ID,
    GET_ALL_ANNOUN_TEMPLATE,
    GET_ANNOUN_TEMPLATE_BY_ID,
    GET_WORKGROUP,
    GET_ALL_ANNOUN_TAGS,
    GET_RECIPIENTS_BY_CLASSIDS,
    GET_RECIPIENTS_BY_LOCATIONIDS,
    GET_ALL_ANNOUNCEMENT,
    GET_ANNOUNCEMENT_USERS,
} from '@/api/constant.js'
import {
    handleResponse
} from '@/api/handle-response'
import {
    requestOptions
} from '@/api/request-options'

function createTemplate(data) {
    return fetch(ROOT + CREATE_ANNOUN_TEMPLATE, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function createAnnouncement(data) {
    return fetch(ROOT + CREATE_ANNOUNCEMENT, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function getAnnounByID(announID) {
    return fetch(ROOT + GET_ANNOUN_BY_ID + `announID=${announID}`, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function getAllTemplate() {
    return fetch(ROOT + GET_ALL_ANNOUN_TEMPLATE, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function getAllAnnouncement(fromDate, toDate) {
    return fetch(ROOT + GET_ALL_ANNOUNCEMENT + `fromDate=${fromDate}&toDate=${toDate}`, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}
function getAnnouncementUsers(announID) {
    return fetch(ROOT + GET_ANNOUNCEMENT_USERS + `anouncemenId=${announID}`, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function getTemplateByID(templateID) {
    return fetch(ROOT + GET_ANNOUN_TEMPLATE_BY_ID + `templateID=${templateID}`, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function getWorkgroup() {
    return fetch(ROOT + GET_WORKGROUP, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function getAllTags() {
    return fetch(ROOT + GET_ALL_ANNOUN_TAGS, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function getRecipientsByClassIDs(classIDs, workGroupIDs) {
    return fetch(ROOT + GET_RECIPIENTS_BY_CLASSIDS + `classIDs=${classIDs}&workGroupIDs=${workGroupIDs}`, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function getRecipientsByLocationIDs(locationIds, workGroupIds) {
    return fetch(ROOT + GET_RECIPIENTS_BY_LOCATIONIDS + `locationIds=${locationIds}&workGroupIds=${workGroupIds}`, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function cancelAnnouncement(announID) {
    return fetch(
            ROOT +
            CANCEL_ANNOUNCEMENT +
            `announID=${announID}`,
            requestOptions.put()
        )
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function editTags(data) {
    return fetch(ROOT + EDIT_ANNOUN_TAGS, requestOptions.put(data))
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function editTemplate(data) {
    return fetch(ROOT + EDIT_ANNOUN_TEMPLATE, requestOptions.put(data))
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function editAnnouncement(data) {
    return fetch(ROOT + EDIT_ANNOUNCEMENT, requestOptions.put(data))
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function deleteTemplate(templateID) {
    return fetch(ROOT + DELETE_ANNOUN_TEMPLATE + 'templateID=' + templateID, requestOptions.delete())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function deleteTag(announTagID) {
    return fetch(ROOT + DELETE_ANNOUN_TAG + 'announTagID=' + announTagID, requestOptions.delete())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

export const announcement = {
    createTemplate,
    createAnnouncement,
    getAllAnnouncement,
    getAnnounByID,
    getAllTemplate,
    getTemplateByID,
    getWorkgroup,
    getAllTags,
    getRecipientsByClassIDs,
    getRecipientsByLocationIDs,
    cancelAnnouncement,
    editTags,
    editTemplate,
    editAnnouncement,
    deleteTemplate,
    deleteTag,
    getAnnouncementUsers,
}